import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import home from '../assets/video-3.mp4';
import home from '../assets/home-6.jpg';
import home7 from '../assets/home7.jpg';
import { HiChevronDoubleRight } from "react-icons/hi";
import logo_back from '../assets/logo_back.png';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { homeAnimation, homeInfoAnimation } from "animation";
import { RiWhatsappFill } from 'react-icons/ri';

function Home() {
  const [showWhatsapp, setShowWhatsapp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowWhatsapp(!showWhatsapp);
    }, 1500);
  }, []);

  return (
    <Section id="home">
      <Navbar />
      <img className='video_bg' src={home} alt="" />
      <motion.div className="home"
      variants={homeAnimation}
      transition={{ delay: 0.3, duration: 0.6, type: "tween"}}
      >
        {/* <video className='video_bg' src={home} autoPlay loop muted /> */}
        <div className="content">
          <div className="title">
            <h1>DAPPERS</h1>
            <hr className='line' /><br />
            <p className='tag'>Appearance Matters...</p>
          </div>
          <div className="subTitle">
            <p>Dappers services will pamper you so well that you will walk out with a positive <strong>ATTITUDE</strong>...</p>
          </div>
          <div className='arrows_btn' style={{display: 'flex', marginLeft: '-0.8rem', marginTop: '-2.5rem'}}>
            <HiChevronDoubleRight className='arrows' color="#e9e572" fontSize="4em"/>
            <HiChevronDoubleRight className='arrows' color="#e9e572" style={{marginLeft: '-1.7rem'}} fontSize="4em"/>
          <a href="#contact" id="vidBtn">Need Appointment ? Click Me!</a>
          </div>
          <div className='whatsapp'>
            {showWhatsapp && <a id='whatsapp'
              
              href="https://wa.me/971553589717" 
              target="_blank" rel="noopener noreferrer" 
            >
              <RiWhatsappFill/>
            </a>}  
          </div>
        </div>
      </motion.div>      
    </Section>
  )
}

const Section = styled.section`
  min-height: 100vh;
  position: relative;

  @media (min-width: 280px) and (max-width: 480px){ // - mobile devices
    min-height: 100vh;
    min-width: 50% !important;
    position: relative;
    .video_bg{
    position: absolute;
    top: 0 !important;
    left: 0 !important; 
    width: 100%;
    height: 35% !important;
    object-fit: contain repeat;
  } 
  /* z-index: -1; */
  .home{
    height: 100%;
    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 19rem;
      padding-left: 19rem;
      .title {
        color: black;
        margin: -9rem 2rem 0 -16rem !important;
        h1 {
          color: black;
          /* font-family: var(--dappers-font) !important; */
          font-family: 'Times New Roman';
          font-size: 3.5rem;
          font-weight: 700;          
          line-height: 3rem;
          letter-spacing: 2px;
        }
        .line{
          margin-top: 0.5rem !important;
          /* margin-left: -1rem !important; */
          width: 10rem;
          height: 1px !important;
          float: right;
        }
        .tag{
          width: 10rem;
          /* float: right; */
          margin-top: -0.5rem;
          margin-left: 7.5rem;
          font-family: Calibri;
          font-size: 0.8rem;
          font-weight: 700;
          letter-spacing: 1.5px;
          color: black;
        }
      }
      .subTitle {
        margin: 0 0 0 -16rem;
        p {
          text-align: justify;
          font-size: 1.2rem;
          line-height: 2rem;
          color: black;
          letter-spacing: 0.5px;
          width: 17rem;
          /* margin-bottom: 2rem; */
        }
      }
      .arrows_btn{
        margin: 2.2rem 0 0 -17rem !important;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        .arrows{
          /* display: none; */
        }
      }
      
      #vidBtn{
        /* margin-left: 0.5rem; */
        font-family: 'Roboto Condensed', sans-serif;
        display: flex;
        font-size: 0.7rem;
        text-align: center;
        letter-spacing: 0.03rem;
        width: 12rem;
        height: 2.5rem;
        padding-top: 4%;
        padding-right: -2% !important;
        padding-left: 5%;
        border: none;
        background: #e9e572;
        color: #333;
        text-decoration: none;
        cursor: pointer;
        border-radius: 50px;            
      }
      #vidBtn:hover{    
        background: #e9e572;
          color: #333;
          text-decoration: none;  
          text-transform: uppercase;  
      }
      .whatsapp{
        margin-top: -10rem;
        margin-left: -1.2rem !important;
      }
      #whatsapp{
        font-size: 2.5rem; 
        padding: 0.5rem !important; 
        background-color: #70d470 !important; 
        color: #fff !important;
        position: sticky;
        background-color: var(--secondary-color);
        padding: 1rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        transition: 0.4s ease-in-out;
        box-shadow: 0 8px 35px 5px rgb(222, 221, 176);  
        z-index: 1;  
      }      
    }
  }
  }

  @media (min-width: 480px) and (max-width: 720px){
    min-height: 100vh;
    min-width: 60% !important;
  position: relative;
    .video_bg{
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 80% !important;
    object-fit: cover;
    z-index: -1;
  } 
  .home{
    height: 100%;
    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 8rem;
      padding-left: 18rem;
      .title {
        margin: -6rem 0 0 -10rem;
        h1 {
          color: #fff;
          font-family: var(--dappers-font);
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 3rem;
        }
        .line{
          margin-top: -0.8rem;
          width: 10rem;
          float: right;
        }
        .tag{
          float: right;
          margin-top: -2rem;
          font-family: Calibri;
          font-size: 0.7rem;
          font-weight: 500;
          letter-spacing: 1.5px;
          color: white;
        }
      }
      .subTitle {
        margin: -1rem 0 0 -10rem;
        p {
          text-align: justify;
          font-size: 0.7rem;
          color: white;
          letter-spacing: 0.7px;
          width: 11.2rem;
          margin-bottom: 2rem;
        }
      }
      .arrows_btn{
        margin: -2rem 0 0 -7.5rem !important;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        .arrows{
          display: none;
        }
      }
      
      #vidBtn{
        margin-left: -2.7rem;
        font-family: 'Roboto Condensed', sans-serif;
        display: flex;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 0.03rem;
        width: 11.5rem;
        height: 2.1rem;
        padding: 9px 9px;
        border: none;
        background: #ffffff20;
        color: white;
        text-decoration: none;
        cursor: pointer;
        border-radius: 50px;            
      }
      #vidBtn:hover{    
        background: #e9e572;
          color: #333;
          text-decoration: none;  
          text-transform: uppercase;  
      }
      #whatsapp{
        right: 32px; 
        font-size: 2.5rem; 
        padding: 0.5rem !important; 
        background-color: #70d470 !important; 
        color: #fff !important;
        position: absolute;
        right: -62% !important;
        bottom: -32% !important; 
        background-color: var(--secondary-color);
        padding: 1rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        transition: 0.4s ease-in-out;
        box-shadow: 0 8px 35px 5px rgb(222, 221, 176);    
      }      
    }
  }
  }

  @media (min-width: 720px) and (max-width: 950px){ //  - tablet portrait
    min-height: 100vh;
    min-width: 50% !important;
    position: relative;
    .video_bg{
    position: absolute;
    top: 0 !important;
    left: 0 !important; 
    width: 100%;
    height: 35% !important;
    object-fit: contain repeat;
  } 
  /* z-index: -1; */
  .home{
    height: 100%;
    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* width: 60%; */
      width: 80%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 33rem;
      margin-left: 3.5rem;
      padding-left: 19rem;
      .title {
        color: black;
        margin: -8rem 2rem 0 -16rem !important;
        h1 {
          color: black;
          /* font-family: var(--dappers-font) !important; */
          font-family: 'Times New Roman';
          font-size: 7rem;
          font-weight: 700;          
          line-height: 3rem;
          letter-spacing: 5px;
        }
        .line{
          height: 0.1rem;
          margin-top: 4.5rem;
          margin-left: -19.5rem;
          width: 19rem;
          float: right;
        }
        .tag{
          float: right;
          margin-top: 4.5rem;
          margin-left: -19rem;
          font-family: Calibri;
          font-size: 1.7rem;
          font-weight: 700;
          letter-spacing: 1.7px;
          color: black;
        }
      }
      .subTitle {
        margin: 2rem 0 0 -16rem;
        width: 15rem !important;
        p {
          text-align: justify;
          font-size: 2rem;
          width: 34rem !important;
          line-height: 3.5rem;
          color: black;
          letter-spacing: 1.7px;
          width: 17rem;
          /* margin-bottom: 2rem; */
        }
      }
      .arrows_btn{
        font-size: 1.5rem;
        margin: 4rem 0 0 -17rem !important;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        .arrows{
          /* display: none; */
        }
      }
      
      #vidBtn{
        /* margin-left: 0.5rem; */
        font-family: 'Roboto Condensed', sans-serif;
        display: flex;
        font-size: 1.5rem;
        text-align: center;
        letter-spacing: 0.03rem;
        width: 24.5rem;
        height: 4rem;
        padding-top: 4%;
        padding-right: -2% !important;
        padding-left: 5%;
        border: none;
        background: #e9e572;
        color: #333;
        text-decoration: none;
        cursor: pointer;
        border-radius: 50px;            
      }
      #vidBtn:hover{    
        background: #e9e572;
          color: #333;
          text-decoration: none;  
          text-transform: uppercase;  
      }
      .whatsapp{
        margin-top: -3em;
        margin-left: 19rem !important;
      }
      #whatsapp{
        font-size: 4.5rem; 
        padding: 0.5rem !important; 
        background-color: #70d470 !important; 
        color: #fff !important;
        position: sticky;
        background-color: var(--secondary-color);
        padding: 1rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        transition: 0.4s ease-in-out;
        box-shadow: 0 8px 35px 5px rgb(222, 221, 176);  
        z-index: 1;  
      }      
    }
  }
  }

  @media (min-width: 950px) and (max-width: 1150px){
    min-height: 100vh;
  position: relative;
    .video_bg{
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    z-index: -1;
  } 
  .home{
    height: 100%;
    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 8rem;
      padding-left: 18rem;
      .title {
        margin: -2rem 0 0 -2rem;
        h1 {
          color: #fff;
          font-family: var(--dappers-font);
          font-size: 4.5rem;
          font-weight: 700;
          line-height: 3rem;
        }
        .line{
          width: 13.6rem;
          float: right;
        }
        .tag{
          float: right;
          margin-top: -1rem;
          font-family: Calibri;
          font-size: 1.3rem;
          font-weight: 500;
          letter-spacing: 1.5px;
          color: white;
        }
      }
      .subTitle {
        margin: 0 0 0 -2rem;
        p {
          text-align: justify;
          font-size: 1.1rem;
          color: white;
          letter-spacing: 0.7px;
          width: 20rem;
          margin-bottom: 2rem;
        }
      }
      .arrows_btn{
        margin: -2rem 0 0 -3rem !important;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
      
      #vidBtn{
        font-family: 'Roboto Condensed', sans-serif;
        display: flex;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.03rem;
        width: 15rem;
        height: 2.6rem;
        padding: 10px 15px;
        border: none;
        background: #ffffff20;
        color: white;
        text-decoration: none;
        cursor: pointer;
        border-radius: 50px;            
      }
      #vidBtn:hover{    
        background: #e9e572;
          color: #333;
          text-decoration: none;  
          text-transform: uppercase;  
      }
      #whatsapp{
        right: 32px; 
        font-size: 3.3rem; 
        padding: 0.6rem; 
        background-color: #70d470 !important; 
        color: #fff !important;
        position: absolute;
        right: -62% !important;
        bottom: -32% !important; 
        background-color: var(--secondary-color);
        padding: 1rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        transition: 0.4s ease-in-out;
        box-shadow: 0 8px 35px 5px rgb(222, 221, 176);    
      }      
    }
  }
  }

  @media (min-width: 1150px) and (max-width: 1350px) { //  - tablet landscape   
    min-height: 100vh;
  position: relative;
    .video_bg{
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    z-index: -1;
  } 
  .home{
    height: 100%;
    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 8rem;
      padding-left: 18rem;
      .title {
        h1 {
          color: #fff;
          /* font-family: var(--dappers-font) !important; */
          font-family: 'Javanese Text', 'Noto Serif Japanese', 'Times New Roman' !important;
          font-size: 5rem;
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: 3rem;
        }
        .line{
          width: 14.5rem;
          height: 0.1rem;
          margin-top: 3.5rem;
          margin-left: -15rem;
          /* float: right; */
        }
        .tag{
          float: right;
          margin-top: 3.8rem;
          margin-left: -14.6rem;
          font-family: Calibri;
          font-size: 1.3rem;
          font-weight: 500;
          letter-spacing: 1.5px;
          color: white;
        }
      }
      .subTitle {
        margin: 0 0 0 0.3rem;
        p {
          text-align: justify;
          font-size: 1.2rem;
          color: white;
          letter-spacing: 1.7px;
          width: 22rem;
          margin-bottom: 2rem;
        }
      }
      .arrows_btn{
        margin: -2rem 0 0 0rem !important;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
      
      #vidBtn{
        font-family: 'Roboto Condensed', sans-serif;
        display: flex;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.03rem;
        width: 18rem;
        height: 2.6rem;
        padding: 15px 30px;
        border: none;
        background: #ffffff20;
        color: white;
        text-decoration: none;
        cursor: pointer;
        border-radius: 50px;            
      }
      #vidBtn:hover{    
        background: #e9e572;
        color: #333;
        text-decoration: none;  
        text-transform: uppercase;  
      }
      #whatsapp{
        right: -62% !important;
        bottom: -1% !important; 
        padding: 0.6rem; 
        font-size: 3.3rem; 
        background-color: #70d470 !important; 
        color: #fff !important;
        position: absolute;
        background-color: var(--secondary-color);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        transition: 0.4s ease-in-out;
        box-shadow: 0 8px 35px 5px rgb(222, 221, 176);    
      }      
    }
  }
  }

  @media (min-width: 1350px) and (max-width: 1920px) { //  - laptop   
    min-height: 100vh;
  position: relative;
    .video_bg{
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    z-index: -1;
  } 
  .home{
    height: 100%;
    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 6rem;
      padding-left: 18rem;
      .title {
        h1 {
          color: #fff;
          font-family: var(--dappers-font) !important;
          font-size: 5rem;
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: 3rem;
        }
        .line{
          width: 13.6rem;
          height: 0.1rem;
          margin-top: 0.2rem;
          margin-left: 9.5rem;
          /* float: right; */
        }
        .tag{
          float: right;
          margin-top: -0.8rem;
          margin-left: -13.5rem;
          font-family: Calibri;
          font-size: 1.3rem;
          font-weight: 500;
          letter-spacing: 1.5px;
          color: white;
        }
      }
      .subTitle {
        p {
          text-align: justify;
          font-size: 1.2rem;
          color: white;
          letter-spacing: 1px;
          width: 22rem;
          margin-bottom: 2rem;
        }
      }

      .arrows_btn{
        margin: 0;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
      
      #vidBtn{
        font-family: 'Roboto Condensed', sans-serif;
        display: flex;
        font-size: 0.9rem;
        text-align: center;
        letter-spacing: 0.03rem;
        width: 17rem;
        height: 2.6rem;
        padding: 15px 32px;
        border: none;
        background: #ffffff20;
        color: white;
        text-decoration: none;
        cursor: pointer;
        border-radius: 50px;            
      }
      #vidBtn:hover{    
        background: #e9e572;
          color: #333;
          text-decoration: none;  
          text-transform: uppercase;  
      }
      .whatsapp{
        margin-top: -3rem;
      }
      #whatsapp{
        right: 32px; 
        font-size: 3.3rem; 
        background-color: #70d470 !important; 
        color: #fff !important;
        position: absolute;
        right: -62% !important;
        bottom: -32% !important; 
        background-color: var(--secondary-color);
        padding: 0.6rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        transition: 0.4s ease-in-out;
        box-shadow: 0 8px 35px 5px rgb(222, 221, 176);    
      }      
    }
  }
  }
  
`;

export default Home