import React from 'react';
import styled from 'styled-components';
// import Title from './Title';
import about from '../assets/about.png';
import play from '../assets/play_1.png';
import bg_about from '../assets/bg_about.png';
import { HiChevronDoubleRight } from 'react-icons/hi';

function About () {
  const data = [
    {
      type: "What makes us different?",
      text: "At Dappers, we believe everyone should walk out of our salons looking like a real Dapper who stands out in the crowd. As our tagline states 'Appearance Matters'. It is our goal to exceed your expectations, and give you the look that you deserve. Come and experience a friendly atmosphere where you come as guests and leave as friends. Our staff is highly trained with diversity in all aspects of Haicut, Facial, Moroccan Bath, Hair Colour, Manicure-Pedicure, Body Waxing and more. We keep up with the latest trends in our industry by continually attending hair shows and education classes throughout the year. All our stylists share one common goal – to pay close attention to the little details of every service, which is very important to do a good job. We pledge to maintain the highest standard of hygiene and cleanliness within our facility. Come visit us and let us pamper you and provide you with quality services.",
      image: about,
    },   
  ];
  return <Section id='about'>
    {
      data.map(({type,text,image}, index) => {
        return (          
          <div className="about">
            <h1 className='abt'>About</h1>
            <div className="about__image">
            </div>
            <div className="about__title">
              <h2>{type}</h2>
            </div>
            <p className="about__description">{text}</p>
            <div className='about_arrows'>
              <HiChevronDoubleRight color="#e9e572" fontSize="4em"/>
              <HiChevronDoubleRight color="#e9e572" style={{marginLeft: '-1.7rem'}} fontSize="4em"/>
            </div>
          </div>  
        )    
      })
    }
  </Section>
}

const Section = styled.section`
min-height: 100vh !important;
.abt{
  top: 38rem !important;
  color: red;
  position: absolute;
  transform: translateX(-92%) translateY(20vw) rotate(-90deg);
  font-size: 6rem;
  margin-left: -2rem;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-weight: 400;
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    display: none;
  }
}
  .about{
    /* display: block; */
    align-items: center;
    height: 100%;
    margin: 0rem 10rem 5rem 16rem !important;
    padding-top: 1rem;
    .about__title{
      span{
        color: var(--primary-color);
      }
    }
    .about__description{
      font-family: 'Poppins';
      font-size: 1.1rem;
      line-height: 1.8rem;
      color: black;
      margin-bottom: 2rem;      
    }    
    &__image{
    padding: 2rem;
    margin-bottom: 3rem;           
    } 
    
    span{
      color: #fff;
      font-weight: bolder;
    }
    h2{
      font-size: 3rem;
      line-height:3rem;
      margin-bottom: 3rem;
      color: var(--secondary-color);
    } 
    .about_arrows{
      display: block; 
      margin-left: -0.8rem;
    }   
  }

  @media (min-width: 280px) and (max-width: 480px){ // - mobile devices
    min-height: 100vh !important;
    margin-top: 3rem !important;
    margin-left: 2rem;
    color: red;
    .about{
      /* display: block; */
      /* align-items: center; */
      height: 10%;
      margin: -4rem 0.7rem 0.5rem 0.2rem !important;
      /* padding-top: -2rem !important; */
      .about__title{
        h2{
          margin-top: -8rem;
          font-size: 1.5rem !important;
          line-height: 1.8rem;
          /* color: red !important; */
        }
      }
      .about__description{
        font-family: 'Poppins';
        font-size: 1rem;
        margin-top: -2rem;
        line-height: 1.6rem;
        color: black;
        margin-bottom: 2rem;      
      }    
      &__image{
      padding: 2rem;
      margin-bottom: 3rem;           
      }     
      span{
        color: #fff;
        font-weight: bolder;
      }
      h2{
        font-size: 13rem !important;
        line-height:3rem;
        margin-bottom: 3rem;
        color: var(--secondary-color);
      } 
      .about_arrows{
        font-size: 1.5rem;
        display: block; 
        margin-left: -0.8rem;
      }   
    }
  }
  
  @media (min-width: 720px) and (max-width: 950px){ //  - tablet portrait
    min-height: 10vh !important;
    margin-top: 1rem !important;
    margin-left: 2rem;
    color: red;
    .about{
      height: 10%;
      margin: 5rem 3.5rem !important;
      padding-top: 0rem !important;
      .about__title{
        h2{
          font-size: 2rem !important;
          /* color: red !important; */
        }
      }
      .about__description{
        font-family: 'Poppins';
        font-size: 1.5rem;
        line-height: 2.6rem;
        color: black;
        margin-bottom: 2rem;      
      }    
      &__image{
      padding: 2rem;
      margin-bottom: 3rem;           
      }     
      span{
        color: #fff;
        font-weight: bolder;
      }
      h2{
        font-size: 13rem !important;
        line-height:3rem;
        margin-bottom: 3rem;
        color: var(--secondary-color);
      } 
      .about_arrows{
        font-size: 1.5rem;
        display: block; 
        margin-left: -0.8rem;
      }   
    }
  }

  @media (min-width: 1150px) and (max-width: 1350px){ // - tablet landscape
    min-height: 100vh;
    .about{
      align-items: center;
      height: 100%;
      margin: 1rem 3.5rem !important;
      .about__title{
        h2{
          margin-top: -10rem !important;
          font-size: 3rem !important;
          color: var(--secondary-color);
        }
      }
      .about__description{
        font-family: 'Poppins';
        font-size: 1.4rem;
        line-height: 2.3rem;
        color: black;
        margin-bottom: 2rem;      
      }    
      /* &__image{
      padding: 2rem;
      margin-bottom: 3rem;           
      }      */
      span{
        color: #fff;
        font-weight: bolder;
      }
      h2{
        font-size: 3rem;
        line-height:3rem;
        margin-bottom: 3rem;
        color: var(--secondary-color);
      } 
      .about_arrows{
        font-size: 1.5rem;
        display: block; 
        margin-left: -0.8rem;
      } 
    }
  }
  
`;

export default About