import React, { useState } from 'react';
import styled from 'styled-components';
import service_1 from '../assets/service_1.png';
import service_2 from '../assets/service_2.jpeg';
import service_3 from '../assets/service_3.jpeg';
import service_4 from '../assets/service_4.jpeg';
import service_6 from '../assets/service_6.jpeg';
import service_7 from '../assets/service_7.jpg';
import { useScroll } from '../components/useScroll';
import { motion } from 'framer-motion';
import { servicesAnimations } from "animation";
import './services.css';
import Accordion from './accordion';

function Services() {
  const [element, controls] = useScroll();
  const [showServices, setShowServices] = useState(false);
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState(null);
  const data = [
    {
      type: "Hair and Beard",
      value: 's1',
      text: [
        {id: "1", info: "Dappers Signature Hair Cut (With Wash and styling)"},
        {id: "2", info: "Beard Styling "},
        {id: "3", info: "Beard Trim or Shave"},
        {id: "4", info: "Kid's Hair Cut"},
        {id: "5", info: "Wash and Styling"},
        {id: "6", info: "Hair Wash"},
        {id: "7", info: "Hair Cut (Long Hair)"},
        {id: "8", info: "Threading"},
        {id: "9", info: "Beard Styling (Long)"},
        {id: "10", info: "Head Shave"},
      ],
      image: service_1,
    },
    {
      type: "Body Shaving and Waxing",
      value: 's2',
      text: [
        {id: "1", info: "Arms"},
        {id: "2", info: "Under Arms"},
        {id: "3", info: "Chest"},
        {id: "4", info: "Back"},
        {id: "5", info: "Full Legs"},
        {id: "6", info: "Full Body"},
        {id: "7", info: "Nose"},
        {id: "8", info: "Ears"},
        {id: "9", info: "Full Face"},
      ],
      image: service_2,
    },
    {
      type: "Face and Body Care",
      value: 's3',
      text: [
        {id: "1", info: "D‐Tan (with Scrubs)"},
        {id: "2", info: "Face Polish"},
        {id: "3", info: "Regular Facial"},
        {id: "4", info: "Executive Facial"},
        {id: "5", info: "Dappers Signature Facial "},
        {id: "6", info: "Nose Strip"},
        {id: "7", info: "Casmara Skin Sensation"},
        {id: "8", info: "Casmara Whitning"},
        {id: "9", info: "Casmara Shine stop"},
        {id: "10", info: "Casmara Mask"},
        {id: "11", info: "Casmara with Skin Peeling"},
        {id: "12", info: "Casmara Mask(G)"},
        {id: "13", info: "Casmara with Skin Peeling(Gold)"},
        {id: "14", info: "O3+ Facial"},
        {id: "15", info: "Casmara Age Defense"},
        {id: "16", info: "Head Massage"},
      ],
      image: service_3,
    },
    {
      type: "Hair and Beard Color",
      value: 's4',
      text: [
        {id: "1", info: "Hair Color (Amonia Free)"},
        {id: "2", info: "Hair Color"},
        {id: "3", info: "Beard Color (Amonia Free)"},
        {id: "4", info: "Beard Color"},
        {id: "5", info: "Highlights and Lowlights"},
        {id: "6", info: "Fashion Hair Color"},
      ],
      image: service_4,
    },    
    {
      type: "Hair and Scalp Treatment",
      value: 's5',
      text: [
        {id: "1", info: "Hair Keratin"},
        {id: "2", info: "Hair Botox "},
        {id: "3", info: "Hair Permingl/Curl"},
        {id: "4", info: "Hair Relaxing"},
        {id: "5", info: "Anti‐Dandruff or Dry Scalp"},
        {id: "6", info: "Hair Spa"},
        {id: "7", info: "Anti‐Hair Fall or Hair Loss"},
        {id: "8", info: "Self Color Box"},
      ],
      image: service_6,
    },
    {
      type: "Moroccan Bath & Foot Spa",
      value: 's6',
      text: [
        {id: "1", info: "Morroccan Bath (VIP)"},
        {id: "2", info: "Manicure"},
        {id: "3", info: "Morroccan Bath (60 Minutes)"},
        {id: "4", info: "Foot Spa"},
        {id: "5", info: "Morroccan Bath (45 Minutes)"},
        {id: "6", info: "Pedicure"},
        {id: "7", info: "Express Cut and File"},
      ],
      image: service_7,
    },
  ];

  const toggle = (index) => {
    // index === selected ? setShowServices(false) : setShowServices(true);
    if(selected === index){
        return setSelected(null)
    }
    setSelected(index);
    setShow((prevState) => !prevState);
  };

  return (
      <Section id="services" ref={element}>
      <div className="services">        
        <motion.div
          className="services__service"
          variants={servicesAnimations}
          transition={{ delay: 0.03, type: "tween", duration: 0.5 }}
          animate={controls}
        >
        <div className="container">
            <Accordion>
            {data.map((hero, index) => (
                <Accordion.Item key={index}>
                  <Accordion.Header>
                    <Accordion.Title>{hero.type}</Accordion.Title>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion.Image src={hero.image}/> 
                    <ul className='grid'>                    
                        {hero.text.map((t) => (                            
                            <Accordion.Content>{t.info}</Accordion.Content>                            
                        ))}
                    </ul>  
                  </Accordion.Body>
                </Accordion.Item>
            ))}
            </Accordion>
        </div>
        </motion.div>
      </div>
    </Section>
  );
}

const Section = styled.section`
min-height: 100vh;

    .container {
        margin: 3rem 0 2rem 15rem; 
        @media (min-width: 280px) and (max-width: 480px){
            margin-top: 8rem !important;
        } 
        @media (min-width: 720px) and (max-width: 950px){ // - tablet portrait
            /* margin-left: 29rem !important; */
          margin-top: 15rem !important;
        }     
        @media (min-width: 1150px) and (max-width: 1350px) { // - tablet landscape
          margin-top: -5rem;
        }
    }    
    .txt{
        @media (min-width: 280px) and (max-width: 480px){
            margin-left: -7rem !important;
        }
        @media (min-width: 480px) and (max-width: 720px){
            margin-left: 1rem !important;
        }
        @media (min-width: 720px) and (max-width: 950px){
            margin-left: 16rem !important;
        }
        @media (min-width: 950px) and (max-width: 1150px){
            margin-left: 29rem !important;
        }
    }
    .image{
        @media (min-width: 280px) and (max-width: 480px){
            display: none;
        }        
    }
.arrows{
  margin-left: 26rem;
  position: relative !important;
  @media (min-width: 280px) and (max-width: 480px){
      margin-left: -7rem !important;
  }
  @media (min-width: 480px) and (max-width: 720px){
      margin-left: 1rem;
  }
  @media (min-width: 720px) and (max-width: 950px){  // - tablet portrait
      margin-left: 16rem;
  }
  @media (min-width: 950px) and (max-width: 1150px){
      margin-left: 28.9rem;
  }
  /* @media (min-width: 1350px) and (max-width: 1920px){
    margin-left: 10rem !important;
  } */
    }
    ul{
        display: grid;
        grid-template-columns: repeat(4, 10rem);
        @media (min-width: 280px) and (max-width: 480px){
            grid-template-columns: repeat(2, 8rem);            
            padding-bottom: 0.5rem;
        }
        @media (min-width: 480px) and (max-width: 720px){
            grid-template-columns: repeat(2, 10rem);            
            padding-bottom: 0.5rem;
        }
        @media (min-width: 720px) and (max-width: 950px){
            grid-template-columns: repeat(2, 15rem);
            height: 12rem;
        }
    }  
`;

export default Services;