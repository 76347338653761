import styled from 'styled-components';

export const Container = styled.div`
background-color: var(--secondary-color);
    width: 58rem;
    /* height: 600px; */
    margin: 4rem 1rem 1rem 15rem;     
    border-radius: 50px;
    box-shadow: 0 8px 35px 5px rgb(210, 210, 206);
    @media (min-width: 280px) and (max-width: 480px){ // - Mobile portrait        
        width: 20.5rem;
        margin-left: -14rem;
    }
    @media (min-width: 480px) and (max-width: 720px){
        width: 32rem;
        margin-left: -13.5rem;
    }
    @media (min-width: 720px) and (max-width: 950px){  // - tablet portrait
        width: 47rem;
        margin-top: 5rem;
        /* margin-top: 20rem !important; */
        margin-left: -13.5rem;
    }
    @media (min-width: 950px) and (max-width: 1150px){
        width: 6rem;
        margin-left: -13.5rem;
    }
    @media (min-width: 1150px) and (max-width: 1350px) { // - tablet landscape
        width: 60rem;
        /* margin-top: 2rem; */
        margin-left: 1rem !important;
    }
    @media (min-width: 1350px) and (max-width: 1920px) { // - laptop
        width: 60rem;
        margin-top: 2rem;
        margin-left: 0rem !important;
    }
`

export const Item = styled.div`
display: grid;
`
export const Header = styled.h4`
display: flex;
text-align: center;
padding: 0.1rem;
margin-bottom: 0.5rem;
`
export const Title = styled.h4`
font-family: 'Poppins';
margin-top: 1rem;
margin-left: 2.2rem;
margin-bottom: 0 !important;
width: 20rem;
text-align: left;
/* color: #e9e572; */
color: #333;
padding: 1rem;
@media (min-width: 280px) and (max-width: 480px){ // - Mobile portrait 
    font-size: 0.7rem !important;
    font-weight: 500;
}
@media (min-width: 480px) and (max-width: 720px){
    font-size: 0.8rem !important;
    font-weight: 500;color: red !important;
}
@media (min-width: 720px) and (max-width: 950px){ // - tablet portrait
    font-size: 1rem !important;
    font-weight: 500;
}
@media (min-width: 1150px) and (max-width: 1350px){
    font-size: 1.05rem !important;
    font-weight: 600;   
}
`
export const Body = styled.div`
/* margin: 1rem; */
margin: -1rem 1rem 2rem 1rem;
padding: 0.5rem 1rem 0 1rem;
display: grid;
grid-template-columns: repeat(4, 0.2fr);
font-family: 'Poppins';
margin-bottom: 1rem;
border-radius: 30px;
background-color: #fff;
transition: 0.5s ease-in-out;
/* background: linear-gradient(90deg, rgba(251,248,175,1) 0%, rgba(242,239,170,1) 22%, rgba(185,183,139,1) 80%, rgba(169,167,130,1) 100%, rgba(206,204,151,1) 100%, rgba(88,88,86,1) 100%); */

@media (min-width: 280px) and (max-width: 480px){ // - Mobile portrait
    width: 19.3rem;
    /* text-align:  !important; */
    grid-template-columns: repeat(2, 4.5rem);
    gap: 0.1rem;
    margin-top: -1rem;
    margin-right: 1rem;
    margin-left: 0.65rem !important;
    height: 7.5rem;
}
@media (min-width: 480px) and (max-width: 720px){
    width: 30rem;
    grid-template-columns: repeat(2, 10rem);
}
@media (min-width: 720px) and (max-width: 950px){ // - tablet portrait
    width: 45rem !important;
    grid-template-columns: repeat(2, 15rem);
    height: 14rem;
    font-family: 'Poppins';
    padding-top: 1.25rem;
}
@media (min-width: 1150px) and (max-width: 1350px) { // - tablet landscape
    height: 12rem;
}

`
export const Content = styled.li`
font-family: 'Poppins';
font-size: 0.75rem;
font-weight: 600;
margin-left: 2rem;
list-style-type: circle !important;
@media (min-width: 280px) and (max-width: 480px){  // - Mobile portrait   
    /* font-size: 1rem !important; */
    font-size: 0.5rem;
    font-weight: 300;
    margin-left: 0.5rem;
}
@media (min-width: 480px) and (max-width: 720px){
    font-size: 0.45rem;
    font-weight: 300;
    margin-left: 2rem;
}
@media (min-width: 720px) and (max-width: 950px){ //- tablet portrait
    font-size: 0.8rem !important;    
    font-weight: 300;
    margin-top: 0.2rem;
    margin-left: 2rem;
}
@media (min-width: 1150px) and (max-width: 1350px) { // - tablet landscape
    margin: 0.5rem 0 0.1rem 2rem;
}
`
export const Image = styled.img`
  width: 200px;
  height: 125px;
  margin-bottom: 0.5rem;
  margin-left: 1.2rem;
  border-radius: 10px;
  @media (min-width: 280px) and (max-width: 480px){ // - Mobile portrait
    width: 70px;
    height: 50px;
    margin-top: 1.8rem;
    margin-left: -0.5rem;
    }
  @media (min-width: 480px) and (max-width: 720px){
    width: 100px;
    height: 65px;
    margin-top: 0.3rem;
  }
  @media (min-width: 720px) and (max-width: 950px){ //  - tablet portrait
    width: 200px;
    height: 125px;
    margin-top: 2rem;
}
@media (min-width: 1150px) and (max-width: 1350px) { // - tablet landscape
    margin-top: 1.8rem;
}
`


