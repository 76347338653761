import React, { useState } from 'react';
import styled from 'styled-components';
// import logo from '../assets/dappers_logo.svg';
// import logo from '../assets/new_final_logo.png';
import logo from '../assets/new_final_logo_noblack.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
// import {  navLinks } from '../constants';
import { useScroll } from '../components/useScroll';
import { motion } from 'framer-motion';
import { navAnimation } from "animation";

function Navbar() {
  const [isNavOpen,setIsNavOpen] = useState(false);
  const [element, controls] = useScroll();
  return <Nav ref={element} className='w-full flex py-6 justify-between items-center navbar'
  variants={navAnimation}
  transition={{ delay: 0.1 }}
  animate={controls} 
  state={isNavOpen ? 1 : 0}
  >
    <div className="brand__container">
      <a href="#" className='brand'>
        <img src={logo} alt="logo"/>
      </a>  
      <div className="toggle">
        {isNavOpen ? (
          <MdClose onClick={ () => setIsNavOpen(false)} />
        ) : (
          <GiHamburgerMenu
            onClick={ (e) => {
              e.stopPropagation();
              setIsNavOpen(true);
            }}
            />
        )}
      </div>
    </div>
    <div className={`links ${isNavOpen ? "show" : ""}`}>    
      <ul>
        <li >
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#about">about</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#stats">stats</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#testimonials">testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  </Nav>
}

const Nav = styled(motion.nav)`
position: absolute;
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
  color: #fff;
  padding-top: 2rem;
  z-index: 1;
  .brand__container {
    margin: 0 2rem;
    .toggle {
      display: none;
    }
  }
  img{
    margin-top: -20px;
    margin-left: -30px;
    height: 200px;
    width: 200px;
  }
  .links {
    ul {
      list-style-type: none;
      display: flex;
      gap: 2rem;
      .active{
        a {
          border-bottom: 0.2rem solid var(--secondary-color);
        }
      }
      li {
        a {
          color: #fff;
          text-decoration: none;
          position: relative;
          font-weight: 400;
          font-size: 1rem;
          text-transform: uppercase;
        }
        a::after{
          content: '';
          width: 0;
          height: 3px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          background: var(--secondary-color);
          transition: width 0.3s;
        }
        a:hover::after{
          width: 75%;
        }
      }
    }
  }
  @media (min-width: 280px) and (max-width: 480px){
  margin: 0;
    position: relative;
    img{
      margin-top: -1.5rem ;
      margin-left: -1.5rem;
      height: 100px;
      width: 95px;
    } 
    .brand__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        font-size: 1.5rem;
        margin-top: -2rem;
        display: block;
        z-index: 1;
      }
    }
    .show {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .links {
      position: absolute;
      overflow-x: hidden;
      top: 0;
      right: 0;
      width: ${({ state }) => (state ? "100%" : "0%")};
      height: 100vh;
      background-color: var(--secondary-color);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s ease-in-out;
      ul {
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: center;
      }
    }
  }

  @media (min-width: 480px) and (max-width: 720px){
    margin: 0;
    position: relative;
    img{
      margin-top: -1.5rem ;
      margin-left: -1.5rem;
      height: 120px;
      width: 120px;
    } 
    .brand__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        font-size: 1.5rem;
        margin-top: -2rem;
        display: block;
        z-index: 1;
      }
    }
    .show {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .links {
      position: absolute;
      overflow-x: hidden;
      top: 0;
      right: 0;
      width: ${({ state }) => (state ? "100%" : "0%")};
      height: 100vh;
      background-color: var(--secondary-color);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s ease-in-out;
      ul {
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: center;
      }
    }
  }
  @media (min-width: 720px) and (max-width: 950px){  // - tablet portrait
    margin: 0;
    position: relative;
    img{
      margin-top: -1.5rem ;
      margin-left: -1.5rem;
      height: 160px;
      width: 160px;
    } 
    .brand__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        font-size: 2rem;
        margin-top: -2rem;
        display: block;
        z-index: 1;
      }
    }
    .show {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .links {
      position: absolute;
      overflow-x: hidden;
      top: 0;
      right: 0;
      width: ${({ state }) => (state ? "100%" : "0%")};
      height: 100vh;
      background-color: var(--secondary-color);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s ease-in-out;
      ul {
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: center;
      }
    }
  } 
  
  @media (min-width: 950px) and (max-width: 1150px){
    position: relative;  
    img{
      margin-top: -2rem;
      margin-left: -3rem;
      height: 170px;
      width: 170px;
    }  
    .links {
    ul {
      padding-top: 1rem;
      list-style-type: none;
      display: flex;
      gap: 0.2rem;
      .active{
        a {
          border-bottom: 0.2rem solid var(--secondary-color);
        }
      }
      li {
        a {
          margin-bottom: 3rem !important;
          margin-right: 1rem;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          font-size: 0.9rem !important;
          text-transform: uppercase;
        }
      }
    }
  }
  }
  
  @media (min-width: 1150px) and (max-width: 1350px){ // - tablet landscape
    position: relative;  
    img{
      margin-top: -2rem;
      margin-left: -3rem;
      height: 170px;
      width: 170px;
    }  
    .links {
    ul {
      padding-top: 1rem;
      list-style-type: none;
      display: flex;
      gap: 0.2rem;
      .active{
        a {
          border-bottom: 0.2rem solid var(--secondary-color);
        }
      }
      li {
        a {
          margin-bottom: 3rem !important;
          margin-right: 1rem;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          font-size: 1rem !important;
          text-transform: uppercase;
        }
      }
    }
  }
  }

  @media (min-width: 1350px) and (max-width: 1920px){
    /* margin: 0; */
    position: relative;  
    img{
      margin-top: -2rem;
      margin-left: -3rem;
      height: 180px;
      width: 180px;
    }  
    .links {
    ul {
      padding-top: 1.5rem;
      list-style-type: none;
      display: flex;
      gap: 0.2rem;
      .active{
        a {
          border-bottom: 0.2rem solid var(--secondary-color);
        }
      }
      li {
        a {
          margin-bottom: 3rem !important;
          margin-right: 1rem;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          font-size: 0.9rem !important;
          text-transform: uppercase;
        }
      }
    }
  }
  }
  
`;

export default Navbar;