import React, { useState } from 'react';
import loadmore from "assets/loadmore-3.png";
import styled from "styled-components";
import image_1 from '../assets/portfolio1.jpg';
import image_2 from '../assets/portfolio3.jpg';
import image_3 from '../assets/portfolio4.png';
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { testimonialsAnimations } from "animation";
import { testimonialData } from './TestimonialsData';
import { Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './testimonials.css';
import { Swiper, SwiperSlide } from 'swiper/react';

function Testimonials() {
  const [element, controls] = useScroll();
  const [selected, setSelected] = useState(0);
  
  return (
    <Section id='testimonials' ref={element}>
      <div className="container">    
        <div className="testimonials">
          <Swiper className="testimonials__container grid"
          // install Swiper modules
          modules={[Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          grabCursor={true}
          pagination={{ clickable: true }}
          >            
            {
            testimonialData.map(({ id, name, stars, message}, index)=> {
              return (
                <SwiperSlide className="testimonial__item" key={id}>
                {/* <SwiperSlide> */}
                  <motion.div className='testimonial' key={index}
                    variants={testimonialsAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }}
                  >  
                    <div className="title-container">
                      <h3 className='title'>{name}</h3>
                      <span className="designation">
                        {stars}
                      </span>
                    </div>
                    <p className="description">{message}</p>                  
                  </motion.div>
                </SwiperSlide>
              );})
            }   
          </Swiper>     
        </div>        
      </div>
    </Section>
  )
}

const Section = styled.section`
overflow: hidden;

.container {
  min-height: 90vh;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  .testimonials {
    display: flex;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    width: 30%;
    margin-bottom: 1rem;
    .testimonial {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .image {
        position: relative;
        .circle2 {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 10rem;
          width: 10rem;
          border-radius: 10rem;
          background-color: #8860e6b0;
          img{
            height: 150px;
            width: 150px;
            border-radius: 100%;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    color: #fff;
    .designation {
      height: 8rem;
    }
  }  
}

@media screen and (min-width: 280px) and (max-width: 1080px) { 
  .background {
    display: none;
  }
  .container {
    padding: 4rem 0;
    .testimonials {
      width: 80%;
      .testimonial {
        .description {
          height: 18rem;
        }
      }
    }
  }
}
`;

export default Testimonials