export const testimonialData = [
    {
        id: '1',
        name: 'Ankit Ganju',
        stars: '⭐⭐⭐⭐⭐',
        message: 'Dappers gents saloon — an excellent place for styling up. Superb ambiance , great and polite service Super impressed ! Highly recommend !!',
    },
    {
        id: '2',
        name: 'Hamza Motiwala',
        stars: '⭐⭐⭐⭐⭐',
        message: 'I’m very happy with the service at Dappers. They have all services for men- best quality with reasonable prices!. It has a very relaxing ambience. They are very transparent with the products they use too!',
    },
    {
        id: '3',
        name: 'Dolia Antony',
        stars: '⭐⭐⭐⭐⭐',
        message: 'Easily one of the best saloon in dubai, done by pure experts who has a great passion for customer service. A must visit place! Cleanliness is off the roof! and hospitality is off the charts!',
    },
    {
        id: '4',
        name: 'Fábio Mirra',
        stars: '⭐⭐⭐⭐⭐',
        message: "Came here twice already. Pedicure and haircut, all amazing. The facilities are top notch and the best part is the staff, which is super nice and very professional. They know what they are doing and make you feel comfortable and relaxed from the moment you seat until you leave. I'll definitely keep coming back and make it my new go to saloon.",
    },
    {
        id: '5',
        name: 'Sahil Amaan',
        stars: '⭐⭐⭐⭐⭐',
        message: 'I am not giving them a feedback here, I am simply writing my feelings. I am living in Dubai for 18 years and without a single shred of doubt I can say Dappers is best Salon I have ever tried. From welcoming you in salon to service your are taking they take care of everything.... they know what they are doing they are best at it...just name the haircut and they will get you there. Their all services are top notch with affordable charges. Thank you guys for making us dapper. Love you 😍 you guys are my first and last stop for every service I would need from head to toe.',
    },
    {
        id: '6',
        name: 'Elsyed Aashan',
        stars: '⭐⭐⭐⭐⭐',
        message: 'One of best salon in Dubai. Staff were very friendly and Professional in their work.. Even the Prices are affordable..One can visit and enjoy the Experience.',
    },
    {
        id: '7',
        name: '   Shine',
        stars: '⭐⭐⭐⭐⭐',
        message: "After struggling with hair stylists that are not passionate with their work for almost 1 1/2 years, was little nervous to get my hair done at a salon. I have very thick hair and it’s very hard to manage and always end up cutting the hair uneven. I was searching the web reviews and by luck, I found Waseem from the year 2018. My fears were put at ease when Waseem expertly brought back health and beauty to my hair. He asked how I wanted my hair cut. Thinking good or bad I needed a decent haircut and said he was in charge. He cut my hair with precision and professional attitude. Got the best haircut with Waseem!. He is thoughtful and careful; he doesn’t whip through the haircut. Spends time with his clients and will take time making adjustments. No rush, great style, trust him with your head!. Now currently he is doing passion for hair styling in 'Dappers Gents Salon' newly opened in October 2022 @ First floor, Al Hudaibah Mall, Mina Road Bur Dubai. If you’re looking for a good hair dressing. I advise for Waseem. He will listen to you but also listen to his advice. He does know what he’s doing and is the “hairdresser “I was looking for. Excellent saloon with good quality of service. It's very clean and hygienic. It was all worth for money. I would definitely suggest my friends as well. “Thank you so much for the wonderful work you do! I love my hair cut and styling till date with Waseem. And best of all, I know I can always depend on walking out of your salon feeling and looking my best!”. My wholehearted wishes & Good Luck to 'Waseem & Dappers Gents Salon'."
    },
    {
        id: '8',
        name: 'Majed Ahmed',
        stars: '⭐⭐⭐⭐⭐',
        message: 'A tidy salon, their work is fair, and their prices are very good! Great place with good quality people and tools! High recommended.',
    },
    {
        id: '9',
        name: 'Ahmad hussam',
        stars: '⭐⭐⭐⭐⭐',
        message: "Best Men's Salon in Dubai Very Good Quality and unique services. A very excellent and clean salon, and the service is excellent. You must try it."
    },
    {
        id: '10',
        name: 'Mohamed Yehea',
        stars: '⭐⭐⭐⭐⭐',
        message: 'The most beautiful salon in the Emirates♡',
    },
    {
        id: '11',
        name: 'Sanjay Thomas',
        stars: '⭐⭐⭐⭐⭐',
        message: 'I would say the best place in Dubai for men hairstyling and self care. Clean and hygienic place, professional and friendly staff. I’m a regular now here and very happy.',
    },
    {
        id: '12',
        name: 'Zainuddin hatim ali',
        stars: '⭐⭐⭐⭐⭐',
        message: 'Mr. Waseem and team are excellent in their professional hairstyling and grooming... never got disappointed. Newly established in October 2022 @ First floor, Al Hudaibah Mall, Mina Road Bur Dubai with free 2 hours parking in the mall !!!. Lively atmosphere and attentive staff all the way ... Best wishes for your successes Waseem Bhai.',
    },
]