export const homeAnimation ={
    hidden: { x: -400, opacity: 0 },
    show: { x: 0, opacity: 1 },
};

export const homeInfoAnimation ={
    hidden: { x: 100, opacity: 0 },
    show: { x: 0, opacity: 1 },
};

export const navAnimation = {
    hidden: { y: -50, opacity: 0 },
    show: { y: 1, opacity: 1 },
};

export const servicesAnimations = {
    hidden: { y: 200, opacity: 0 },
    show: { y: 0, opacity: 1 },
};

export const portfolioAnimations = {
    hidden: { scale: 0, opacity: 0 },
    show: { scale: 1, opacity: 1 },
};

export const statsAnimations = {
    hidden: { scale: 0, opacity: 0 },
    show: { scale: 1, opacity: 1 },
};

export const pricingAnimation = {
    hidden: { y: 200, opacity: 0 },
    show: { y: 0, opacity: 1 },
};

export const testimonialsAnimations = {
    hidden: { scale: 0, opacity: 0 },
    show: { scale: 1, opacity: 1 },
};

export const contactAnimation = {
    hidden: { y: 200, opacity: 0 },
    show: { y: 0, opacity: 1 },
};

export const footerTextAnimation = {
    hidden: { x: -200, opacity: 0 },
    show: { x: 1, opacity: 1 },
};