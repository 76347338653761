import React from 'react';
import styled from 'styled-components';

const AddressMap = () =>{
return (
    <Div className="google-map-code">
        <iframe className='map' title='Dappers' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.8112191440264!2d55.274864114485275!3d25.243282636025086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43fff9fff1b9%3A0x35d225560a7303c3!2sDappers%20Gents%20Salon!5e0!3m2!1sen!2sae!4v1666440405501!5m2!1sen!2sae" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"   frameborder="0" style={{border:0}} aria-hidden="false" tabindex="0"></iframe>        
    </Div>
);
}

const Div = styled.div`
  .map{
    width: 350px;
    height: 200px;
  }

  @media (min-width: 280px) and (max-width: 480px){  // - Mobile portrait
    .map{
      width: 275px;
      height: 150px;
      margin-top: -1rem;
    }
  }
  @media (min-width: 720px) and (max-width: 950px){// - tablet portrait
    .map{
      width: 500px;
      height: 275px;
      margin-top: -1.5rem;
      border-radius: 20px;
      margin-bottom: -2rem;
      border: 1px solid #a7a795be !important;
    }
  }
`

export default AddressMap;