import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
import { FaThumbsUp } from 'react-icons/fa'
import { useScroll } from '../components/useScroll';
import { motion } from 'framer-motion';
import { contactAnimation } from "animation";
import AddressMap from './Map';

function Contact () {
  const [element, controls] = useScroll();
  const form = useRef();  
  const [results, setResults] = useState(false);
  const [sender, setSender] = useState('');
  
  const Message = () => {
    return(
      <div className="message">
        <div className="success" id="success">
          <i className="fa fa-thumbsup" style={{fontSize: '1.5rem'}}>
            <FaThumbsUp/>
          </i><br />
          <b>Thank you! Mr. {sender} </b><br /><br />
          <p style={{color: 'black'}}>Your message well received, <b><i>DAPPERS</i></b> shall get back to you at the earliest.</p>
        </div>             
      </div>
    )
  }
    
  const sendEmail = (e) => {
    e.preventDefault();
    var sender = form.current.value;
    console.log('sed', sender);

    emailjs
    .sendForm(
      'service_t430dek', 
      'template_85g6167', 
      form.current, 
      'QXtY8_bpED07ZjDJf'
    )
    .then((result) => {
        // console.log(result.text);
        // console.log('message sent!');
        // console.log(e.target[0].value);
        setSender(e.target[0].value);
        e.target.reset();    
        setResults(true);    
        setTimeout(() => {
          setResults(false);
        }, 6000);
      }, (error) => {
        console.log(error.text);
    });
  };
  return <Section id='contact' ref={element}>
    <h1 className='contact_heading'>Contact</h1>
    <motion.div className="contact" variants={contactAnimation} animate={controls} transition={{ delay: 0.09, type: "tween", duration: 0.8 }}>
      <div className="contact__title">
        <p>Need appointment...</p>
        <h2>Just Fillout the form!</h2>
      </div>
      <div className="contact__data">
        <div className="contact__data__description">
          <p className='txt'>If you are looking for an appointment or have any queries, comment, feedback just fill out details.</p>          
          <div className='location'>
            <small>
              <strong>Call us on:</strong> 04-564 2645 / 0553589717 / 0552673025
            </small><br />
            <small>
              <strong>Location:</strong> Shop # 107, 1st Floor, <br />
               14 2 B St. - Al Hudaiba. Hudaiba Mall, <br />  Dubai UAE.
            </small><br /><br />
            <AddressMap/><br />
            <small>
              <strong>Email:</strong> dappersdubai@gmail.com
            </small><br />
            <small>
              <strong>Website:</strong> www.dappersdubai.com
            </small>
          </div>
        </div>
        {/* <div className="contact__data"> */}
          <form className='form' ref={form} onSubmit={sendEmail}>
            <input name="user_name" required type="text" placeholder='Your Name' />
            <input name="user_email" required type="email" placeholder='Your Email' />
            <textarea name="message" required placeholder='Your Message'></textarea>
            <button id='submit' type="submit">Send</button>
            <div>{results ? <Message/> : null}</div>
          </form>
        {/* </div>  */}
      </div>  
    </motion.div>
  </Section>  
}

const Section = styled.section`
  .contact_heading{
    margin-top: 42rem;
    color: red;
    position: absolute;
    transform: translateX(-34%) translateY(-38vw) rotate(270deg);
    font-size: 6rem;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-weight: 400;
    @media screen and (min-width: 280px) and (max-width: 1080px) {
      display: none;
    }
  }
  min-height: 80vh;
  .contact{
    color: var(--primary-color);
    margin: 0 8rem;
    &__title{
      margin  : 4.5rem 0 0.1rem 0;
      /* small{
        margin-bottom: 5rem;
        text-transform: uppercase;
        /* letter-spacing: 0.2rem; 
        color: var(--primary-color);
      } */
      h2{
        font-size: 1.6rem;
        color: var(--secondary-color);
      }
    }
    &__data{
      display: grid;      
      grid-template-columns: 0.5fr 0.4fr;
      gap: 2rem;
      &__description{
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        h4{
          font-size: 1.5rem;
        }
        p{
          text-align: justify;
          margin-top: 20px;
        }
        div{
          p{
            strong{
              text-transform: uppercase;
            }
          }
        }
      }
      .form{
        margin-top: -5rem;
        margin-left: 3rem;
        display: flex;
        flex-direction: column;        
        align-items: center;
        gap: 2rem;
        padding: 1.8rem 1.8rem 0.8rem 1.8rem;
        width: 470px !important;
        border-radius: 5%;
        box-shadow: 21px 17px 103px 4px #6d6d5dbf;
        height: 120% !important;
        input, textarea{
          text-transform: none;
          font-family: 'Calibri';
          border: none;
          border-bottom: 0.1rem solid var(--secondary-color);
          width: 100%;
          color: black;
          letter-spacing: 0.05rem;
          padding-bottom: 0.5rem;          
          &:focus{
            outline: none;
          }
          &::placeholder{
            font-family: sans-serif;
            font-size: 0.75rem;
            font-weight: thin !important;
            color: #c6c6c6;
          }
        }
        textarea{
          width: 100%;
          height: 25%;
          resize: none;
        }
        button{
          width: 100%;
          background-color: transparent;
          border: 0.1rem solid var(--secondary-color);
          height: 3rem;
          color: var(--secondary-color);
          text-transform: uppercase;
          font-size: 1.1rem;
          letter-spacing: 0.1rem;
          cursor: pointer;
          transition: 0.5s ease-in-out;
          &:hover{
            background-color: var(--secondary-color);
            color: #fff;
          }
        }
        .message{
          width: 300px;
          position: relative;
          /* margin-bottom: 0.2rem; */
          display: flex;  
          text-align: center;
          .success{
            /* display: flex; */
            font-size: 0.8rem;
            line-height: 1.2rem;
            color: #256725;
            position: absolute;
            /* display: none; */
            animation: buttons .3s linear;
          }          
          @keyframes buttons {
            0%{
              transform: scale(0.1);
            }
            50%{
              transform: scale(0.5);
            }
            100%{
              transform: scale(1);
            }
          }
        }

      }
    }

  }
  @media (min-width: 280px) and (max-width: 480px) {  // - Mobile portrait
  min-height: 132vh;
  .contact {
    margin: 1rem;
    padding: 0 1rem;
    &__title {
      margin: 0;
      text-align: center;
      p {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
      }
      h2 {
        font-size: 1.2rem;
      }
    }
    &__data {
      grid-template-columns: 1fr;
      /* margin: 0; */
      &__description{
        p {          
          width: 300px !important;
          font-size: 0.9rem;
          line-height: 1.5rem;
          margin-right: 5rem !important;
          text-align: left;
        }
      }
      .form {
        margin: 0;
        padding: 0.5rem;
        width: 300px !important;
        height: 100% !important;
        box-shadow: none;
        button {
          width: 10rem;
          height: 2rem;
          box-shadow: rgba(0, 0, 0, 0.27) 0px 2px 3px 2px;
        }
      }
    }
  }
}
  @media (min-width: 720px) and (max-width: 950px){ // - tablet portrait
    min-height: 100vh !important;
    .contact {
    margin: 2rem ;
    padding: 0 1rem;
    width: 50rem;
    display: block;      
      /* grid-template-columns: 2fr; */
    &__title {
      margin: 0;
      text-align: center;
      p {
        font-size: 1.25rem !important;
        margin-bottom: 0.2rem;
      }
      h2 {
        font-size: 1.85rem !important;
      }
    }
    &__data {
      width: 40rem;
      margin-top: -0.5rem;
      grid-template-columns: 1fr !important;
      /* grid-template-columns: repeat 1fr; */
      /* margin: 0; */
      font-size: 1.2rem !important;
      line-height: 2.25rem;
      .location{
        width: 30rem;
        margin-top: 1rem;
        line-height: 1.75rem;
      }
      &__description{
        .txt p {       
          width: 12rem !important;
          line-height: 1.5rem;
          margin-right: 5rem !important;
          text-align: center;
        }
      }
      .form {
        margin-left: 0;
        margin-top: -1rem;
        padding: 3rem 2rem 1rem 2rem;
        width: 40rem !important;
        height: 160% !important;
        border-radius: 20px;
        box-shadow: 21px 17px 103px 4px #6d6d5dbf;
        button {
          width: 20rem;
          height: 3rem;
          box-shadow: rgba(0, 0, 0, 0.27) 0px 2px 3px 2px;
        }
      }
    }
  }
  }
  /* @media screen and (min-width: 1081px) and (max-width: 1370px){ */
  @media (min-width: 1150px) and (max-width: 1350px){ // - tablet landscape }
    .contact_heading{
      margin-top: 44rem;
    }
    .contact{
      margin: 0 10rem 2rem 15rem;
      &__data{
      display: grid;      
      grid-template-columns: 0.7fr 0.6fr;
      gap: 4rem;
      &__description{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        h4{
          font-size: 1.5rem;
        }
        small{
          /* letter-spacing: 0.1rem; */
          text-align: justify;
          margin-top: 0.5rem;
        }
        div{
          p{
            strong{
              text-transform: uppercase;
            }
          }
        }
      }
      .form{
        margin-top: -3.5rem !important;
        display: flex;
        flex-direction: column;        
        align-items: center;
        gap: 2rem;
        input, textarea{
          text-transform: uppercase;
          border: none;
          border-bottom: 0.1rem solid var(--secondary-color);
          width: 100%;
          color: var(--primary-color);
          letter-spacing: 0.05rem;
          padding-bottom: 0.7rem; 
          &:focus{
            outline: none;
          }
          &::placeholder{
            color: var(--secondary-color);
          }
        }
        textarea{
          width: 100%;
          height: 30%;
          resize: none;
        }
        button{
          width: 100%;
          background-color: transparent;
          border: 0.1rem solid var(--secondary-color);
          height: 3rem;
          color: var(--secondary-color);
          text-transform: uppercase;
          font-size: 1.1rem;
          letter-spacing: 0.1rem;
          /* font-weight: bold; */
          cursor: pointer;
          transition: 0.5s ease-in-out;
          &:hover{
            background-color: var(--secondary-color);
            color: #fff;
          }
        }
      }
    }
    }
  }
  @media (min-width: 1350px) and (max-width: 1920px){ // - laptop
    .contact_heading{
    margin-top: 45rem;
    color: red;
    position: absolute;
    transform: translateX(-34%) translateY(-38vw) rotate(270deg);
    font-size: 6rem;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-weight: 400;
    @media screen and (min-width: 280px) and (max-width: 1080px) {
      display: none;
    }
  }
  min-height: 80vh;
  .contact{
    color: var(--primary-color);
    margin: 0 2rem 1rem 12rem;
    &__title{
      margin  : 4.5rem 0 0.1rem 0;
      h2{
        font-size: 1.6rem;
        color: var(--secondary-color);
      }
    }
    &__data{
      display: grid;      
      grid-template-columns: 0.5fr 0.4fr;
      gap: 8rem;
      &__description{
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        h4{
          font-size: 1.5rem;
        }
        p{
          text-align: justify;
          margin-top: 20px;
        }
        div{
          p{
            strong{
              text-transform: uppercase;
            }
          }
        }
      }
      .form{
        margin-top: -5rem;
        margin-left: 3rem;
        display: flex;
        flex-direction: column;        
        align-items: center;
        gap: 2rem;
        padding: 1.8rem 1.8rem 0.8rem 1.8rem;
        width: 470px !important;
        border-radius: 5%;
        box-shadow: 21px 17px 103px 4px #6d6d5dbf;
        height: 115% !important;
        input, textarea{
          text-transform: none;
          font-family: 'Calibri';
          border: none;
          border-bottom: 0.1rem solid var(--secondary-color);
          width: 100%;
          color: black;
          letter-spacing: 0.05rem;
          padding-bottom: 0.5rem;          
          &:focus{
            outline: none;
          }
          &::placeholder{
            font-family: sans-serif;
            font-size: 0.75rem;
            font-weight: thin !important;
            color: #c6c6c6;
          }
        }
        textarea{
          width: 100%;
          height: 25%;
          resize: none;
        }
        button{
          width: 100%;
          background-color: transparent;
          border: 0.1rem solid var(--secondary-color);
          height: 3rem;
          color: var(--secondary-color);
          text-transform: uppercase;
          font-size: 1.1rem;
          letter-spacing: 0.1rem;
          cursor: pointer;
          transition: 0.5s ease-in-out;
          &:hover{
            background-color: var(--secondary-color);
            color: #fff;
          }
        }
        .message{
          width: 300px;
          position: relative;
          /* margin-bottom: 0.2rem; */
          display: flex;  
          text-align: center;
          .success{
            /* display: flex; */
            font-size: 0.8rem;
            line-height: 1.2rem;
            color: #256725;
            position: absolute;
            /* display: none; */
            animation: buttons .3s linear;
          }          
          @keyframes buttons {
            0%{
              transform: scale(0.1);
            }
            50%{
              transform: scale(0.5);
            }
            100%{
              transform: scale(1);
            }
          }
        }

      }
    }

  }
  }

`;

export default Contact